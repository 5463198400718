<template>
  <div>
    <v-container>
      <v-card>
        <v-layout row justify-space-between>
          <v-flex xs12 md12>
            <v-card class="pa-2 mr-2" outlined>
              <template
                v-if="isSetupStateLoading || isLoading || isLoadingData"
              >
                <Loader class="mr-2 mt-6" />
              </template>
              <template v-else>
                <div>
                  <v-tabs
                    v-model="tab"
                    :centered="centered"
                    :grow="grow"
                    :vertical="vertical"
                    :right="right"
                    :prev-icon="
                      prevIcon ? 'mdi-arrow-left-bold-box-outline' : undefined
                    "
                    :next-icon="
                      nextIcon ? 'mdi-arrow-right-bold-box-outline' : undefined
                    "
                    :icons-and-text="icons"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab key="details" href="#details">
                      {{ $vuetify.lang.t("$vuetify.details_step_txt") }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab key="settings" href="#settings">
                      {{ $vuetify.lang.t("$vuetify.profile_settings_tab") }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab key="insights" href="#insights">
                      {{ $vuetify.lang.t("$vuetify.insights_step_txt") }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab
                      v-if="
                        selectedProject.segment.name ===
                        PROJECT_SEGMENTS.PERSONALIZER
                      "
                      key="setup"
                      :href="
                        !initialSetupDoneFunction ? '#setup' : '#dataUpdate'
                      "
                    >
                      {{
                        !initialSetupDoneFunction
                          ? $vuetify.lang.t("$vuetify.setup_step_txt")
                          : $vuetify.lang.t("$vuetify.project.dataset")
                      }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab
                      v-if="
                        selectedProject.segment.name ===
                          PROJECT_SEGMENTS.Maira && !isGptInitialSetupCompleted
                      "
                      key="gptSetupTab"
                      href="#gptSetupTab"
                    >
                      {{ $vuetify.lang.t("$vuetify.setup_step_txt") }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab
                      v-if="
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.Maira &&
                          isGptInitialSetupCompleted) ||
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.PERSONALIZER &&
                          initialSetupDoneFunction)
                      "
                      key="gptDatasets"
                      href="#gptDatasets"
                    >
                      {{
                        $vuetify.lang.t("$vuetify.gpt_profile.maira_datasets")
                      }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>

                    <v-tab
                      v-if="
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.Maira &&
                          isGptInitialSetupCompleted) ||
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.PERSONALIZER &&
                          initialSetupDoneFunction)
                      "
                      key="gptProfiles"
                      href="#gptProfiles"
                    >
                      {{ $vuetify.lang.t("$vuetify.profile_menu_txt") }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <v-tab
                      v-if="
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.Maira &&
                          isGptInitialSetupCompleted) ||
                        (selectedProject.segment.name ===
                          PROJECT_SEGMENTS.PERSONALIZER &&
                          initialSetupDoneFunction)
                      "
                      key="gptSetting"
                      href="#gptSetting"
                    >
                      {{
                        $vuetify.lang.t("$vuetify.gpt_profile.maira_settings")
                      }}
                      <v-icon v-if="icons">mdi-phone</v-icon>
                    </v-tab>
                    <!--                    <v-tab-->
                    <!--                      class="tab-option"-->
                    <!--                      key="playground"-->
                    <!--                      href="#playground"-->
                    <!--                      v-if="selectedProject.segment.name === PROJECT_SEGMENTS.Maira || selectedProject.segment.name === PROJECT_SEGMENTS.PERSONALIZER">-->
                    <!--                      {{ $vuetify.lang.t("$vuetify.solutions_playground_tab") }}-->
                    <!--                      <v-icon v-if="icons">mdi-phone</v-icon>-->
                    <!--                    </v-tab>-->
                    <v-tab-item key="details" value="details">
                      <ProjectDetails />
                    </v-tab-item>
                    <v-tab-item key="settings" value="settings">
                      <Settings />
                    </v-tab-item>
                    <v-tab-item key="insights" value="insights">
                      <Insights />
                    </v-tab-item>
                    <!-- <v-tab-item v-if="!initialSetupDoneFunction" key="setup" value="setup">
                          <Setup @setupCompleted="postSetupFinishAction"/>
                        </v-tab-item> -->
                    <!-- <v-tab-item  key="setup" value="setup"> -->

                    <v-tab-item key="gptSetupTab" value="gptSetupTab">
                      <GptSetup />
                    </v-tab-item>
                    <v-tab-item key="gptDatasets" value="gptDatasets">
                      <GptDatasets />
                    </v-tab-item>
                    <v-tab-item key="gptProfiles" value="gptProfiles">
                      <GptProfile :tabName="tab" />
                    </v-tab-item>
                    <v-tab-item key="gptSetting" value="gptSetting">
                      <GptSetting :tabName="tab" />
                    </v-tab-item>
                    <v-tab-item
                      v-if="
                        selectedProject.segment.name ===
                          PROJECT_SEGMENTS.PERSONALIZER &&
                        !initialSetupDoneFunction
                      "
                      key="setup"
                      value="setup"
                    >
                      <Setup @setupCompleted="postSetupFinishAction" />
                    </v-tab-item>
                    <v-tab-item
                      v-if="
                        selectedProject.segment.name ===
                        PROJECT_SEGMENTS.PERSONALIZER
                      "
                      key="dataUpdate"
                      value="dataUpdate"
                    >
                      <DatasetUpdate />
                    </v-tab-item>
                    <v-tab-item
                      class="pt-4"
                      v-if="
                        selectedProject.segment.name ===
                          PROJECT_SEGMENTS.Maira ||
                        selectedProject.segment.name ===
                          PROJECT_SEGMENTS.PERSONALIZER
                      "
                      key="playground"
                      value="playground"
                    >
                      <v-container v-show="isIframeLoading">
                        <v-row>
                          <v-col class="pt-4" cols="12">
                            <v-skeleton-loader
                              type="list-item-three-line"
                            ></v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </v-container>
                      <iframe
                        :src="getIframeUrl()"
                        frameborder="0"
                        width="100%"
                        height="950px"
                        class="overflow-y-auto overflow-x-hidden"
                        @load="isIframeLoading = false"
                      ></iframe>
                    </v-tab-item>
                  </v-tabs>
                </div>
              </template>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
  ADD_ALERT,
  CREATE_GPT_SETTINGS,
  GET_ALL_TASKS,
  GET_GPT_DATASET_BY_ID,
  GET_GPT_DATASETS,
  GET_GPT_PROFILES,
  GET_GPT_SETTINGS,
  GET_PREVIOUSLY_SETUP_STATE,
  GET_TASK_STATUS,
  GET_TASKS_STATUS,
  LOAD_PROJECT_BY_ID,
  LOAD_SOLUTION_BY_ID,
  UPDATE_GPT_SETUP_STATE,
  DB_GET_TASKS_STATUS,
  DB_STORE_TASKS_STATUS,
} from "@/store/_actiontypes";

import {
  COMMON_TABS,
  GPT_SETUP_STEPS,
  MAIRA_TABS,
  PERSONALIZER_TABS,
  SEGMENTS,
  SEGMENTS_TAB_STORAGE_KEYS,
} from "@/global/_constants";
import ProjectDetails from "@/components/Projects/Details";
import Settings from "@/components/Projects/Settings";
import Insights from "@/components/Projects/Insights";
import Setup from "@/components/Projects/Setup";
import GptSetup from "@/components/Projects/GptSetup";
import DatasetUpdate from "@/components/Projects/DatasetUpdate";
import GptDatasets from "@/components/Projects/GptDatasets/GptDatasets";
import GptProfile from "@/components/Projects/GptProfile/GptProfile";
import GptSetting from "@/components/Projects/GptSetting/GptSetting";
import Loader from "@/components/common/loader/Loader";
import getEnv from "@/config/env";

export default {
  components: {
    Loader,
    ProjectDetails,
    Settings,
    Insights,
    Setup,
    DatasetUpdate,
    GptProfile,
    GptSetting,
    GptSetup,
    GptDatasets,
  },
  data: () => ({
    tab: null,
    icons: false,
    centered: false,
    grow: false,
    vertical: false,
    prevIcon: false,
    nextIcon: false,
    right: false,
    tabs: 4,
    isSetupStateLoading: false,
    isLoadingData: true,
    isIframeLoading: true,
    initialSetupDone: true,
    isLoading: false,
    PROJECT_SEGMENTS: SEGMENTS,
    isGptInitialSetupCompleted: false,
    datasetsProfilesFetched: false,
    gptSetupCompletedNow: false,
    gptFlowUrl: getEnv("VUE_APP_GPT_FLOW_API"),
  }),
  computed: {
    ...mapState({
      user: state => state.account.user,
      selectedProject: state => state.project.selectedProject,
      gptProfiles: state => state.project.gptProfiles,
      gptDatasets: state => state.project.gptDatasets,
      gptSetupState: state => state.project.gptSetupState,
    }),
    initialSetupDoneFunction() {
      return (
        this.selectedProject?.setup_state &&
        this.selectedProject.setup_state.every(
          entry => entry.status === "success",
        )
      );
    },
  },
  mounted() {
    this.updateGptSetupState();
    this.isGptInitialSetupCompleted = false;
  },
  created() {
    this.fetchData();
  },
  watch: {
    gptSetupState: {
      handler(newVal, oldVal) {
        if (newVal.completed) {
          this.isGptInitialSetupCompleted = true;
          this.tab = "gptDatasets";
        } else {
          let allStepsCompleted = true;
          GPT_SETUP_STEPS.forEach(step => {
            if (!newVal[step].completed) {
              allStepsCompleted = false;
            }
          });
          if (allStepsCompleted) {
            this.gptSetupCompletedNow = true;
            this.tab = "gptDatasets";
          }
        }
      },
      immediate: true,
    },
    isGptInitialSetupCompleted: {
      handler(newVal, oldVal) {
        if (newVal && !this.gptSetupCompletedNow) {
          const storageMairaData = localStorage.getItem(
            SEGMENTS_TAB_STORAGE_KEYS.Maira,
          );
          const jsonStorageMairaData = JSON.parse(storageMairaData);
          const storagePersonalizerData = localStorage.getItem(
            SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
          );
          const jsonStoragePersonalizerData = JSON.parse(
            storagePersonalizerData,
          );
          const jsonStorageData = {
            ...jsonStorageMairaData,
            ...jsonStoragePersonalizerData,
          };
          try {
            const savedTab = jsonStorageData[this.selectedProject.id].tab;
            if (
              MAIRA_TABS.includes(savedTab) ||
              COMMON_TABS.includes(savedTab)
            ) {
              this.tab = savedTab;
            } else {
              this.tab = "details";
            }
          } catch (e) {
            this.tab = "details";
          }
        }
      },
      immediate: true,
    },
    gptSetupCompletedNow: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.tab = "gptDatasets";
          this.isGptInitialSetupCompleted = true;
        }
      },
      immediate: true,
    },
    tab: {
      handler(newVal, oldVal) {
        if (newVal)
          if (
            this.selectedProject?.segment?.name === this.PROJECT_SEGMENTS.Maira
          ) {
            const storageData = localStorage.getItem(
              SEGMENTS_TAB_STORAGE_KEYS.Maira,
            );
            if (storageData) {
              try {
                const jsonStorageData = JSON.parse(storageData);
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.Maira,
                  JSON.stringify({
                    ...jsonStorageData,
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              } catch (e) {
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.Maira,
                  JSON.stringify({
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              }
            } else {
              localStorage.setItem(
                SEGMENTS_TAB_STORAGE_KEYS.Maira,
                JSON.stringify({ [this.selectedProject.id]: { tab: newVal } }),
              );
            }
          } else if (
            this.selectedProject?.segment?.name ===
            this.PROJECT_SEGMENTS.PERSONALIZER
          ) {
            const storageData = localStorage.getItem(
              SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
            );
            if (storageData) {
              try {
                const jsonStorageData = JSON.parse(storageData);
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
                  JSON.stringify({
                    ...jsonStorageData,
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              } catch (e) {
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
                  JSON.stringify({
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              }
            } else {
              localStorage.setItem(
                SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
                JSON.stringify({ [this.selectedProject.id]: { tab: newVal } }),
              );
            }
          } else {
            const storageData = localStorage.getItem(
              SEGMENTS_TAB_STORAGE_KEYS.CV,
            );
            if (storageData) {
              try {
                const jsonStorageData = JSON.parse(storageData);
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.CV,
                  JSON.stringify({
                    ...jsonStorageData,
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              } catch (e) {
                localStorage.setItem(
                  SEGMENTS_TAB_STORAGE_KEYS.CV,
                  JSON.stringify({
                    [this.selectedProject.id]: { tab: newVal },
                  }),
                );
              }
            } else {
              localStorage.setItem(
                SEGMENTS_TAB_STORAGE_KEYS.CV,
                JSON.stringify({ [this.selectedProject.id]: { tab: newVal } }),
              );
            }
          }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("project", [
      LOAD_PROJECT_BY_ID,
      GET_PREVIOUSLY_SETUP_STATE,
      GET_GPT_PROFILES,
      GET_GPT_DATASETS,
      UPDATE_GPT_SETUP_STATE,
      GET_TASKS_STATUS,
      GET_ALL_TASKS,
      GET_GPT_SETTINGS,
      CREATE_GPT_SETTINGS,
      GET_GPT_DATASET_BY_ID,
      DB_GET_TASKS_STATUS,
      DB_STORE_TASKS_STATUS,
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    ...mapActions("solution", [LOAD_SOLUTION_BY_ID]),
    async fetchData() {
      try {
        this.isLoadingData = true;
        // Fetch project data
        await this.getProject();

        // Fetch previous setup state data
        // await this.getPreviouslySetupState();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.isLoadingData = false;
      }
    },
    updateGptSetupState() {
      this.UPDATE_GPT_SETUP_STATE({
        completed: false,
        gpt_profile: { completed: false, profile_id: null },
        default_profile: { completed: false },
        dataset: {
          task_id: null,
          status: null,
          dataset_id: null,
          completed: false,
        },
        training: { task_id: null, status: null, completed: false },
      });
    },
    async createDefaultProfile(profileId) {
      const gptSettingsRes = await this.GET_GPT_SETTINGS({
        project_id: this.selectedProject.id,
      });
      if (!gptSettingsRes?.data?.detail?.settings) {
        this.CREATE_GPT_SETTINGS({
          project_id: this.$route.params.project_id,
          default_profile: profileId,
        })
          .then(res => {})
          .catch(err => {
            console.log("default profile creation error", err);
          });
      }
    },
    async getProject() {
      this.isLoading = true;
      this.isSetupStateLoading = true;
      await this.LOAD_PROJECT_BY_ID({
        project_id: this.$route.params.project_id,
      }).then(
        response => {
          const project = response.data;
          if (project?.segment?.name === this.PROJECT_SEGMENTS.Maira) {
            this.getGptInitialSetupState();
          } else if (
            project?.segment?.name === this.PROJECT_SEGMENTS.PERSONALIZER
          ) {
            this.getPreviouslySetupState();
          } else {
            const storageData = localStorage.getItem(
              SEGMENTS_TAB_STORAGE_KEYS.CV,
            );
            try {
              const jsonStorageData = JSON.parse(storageData);
              const savedTab = jsonStorageData[this.selectedProject.id].tab;
              if (COMMON_TABS.includes(savedTab)) {
                this.tab = savedTab;
              } else {
                this.tab = "details";
              }
            } catch (e) {
              this.tab = "details";
            }
            this.isLoading = false;
            this.isSetupStateLoading = false;
          }
          // this.getSolution(this.selectedProject.solution_template.id);
        },
        error => {
          this.isLoading = false;
          this.isSetupStateLoading = false;
        },
      );
    },

    async getGptInitialSetupState() {
      const taskAPIData = await this.GET_TASKS_STATUS({
        project_id: this.selectedProject.id,
      });

      let dbTaskStatusData = await this.DB_GET_TASKS_STATUS({
        project_id: this.selectedProject.id,
      });
      dbTaskStatusData = dbTaskStatusData?.data?.tasks_status;
      const taskStatus = taskAPIData;
      if (
        taskStatus.completed ||
        (dbTaskStatusData && dbTaskStatusData.completed)
      ) {
        this.isGptInitialSetupCompleted = true;
        this.UPDATE_GPT_SETUP_STATE({ ...taskStatus, completed: true });
        if (!dbTaskStatusData) {
          await this.DB_STORE_TASKS_STATUS({
            project_id: this.selectedProject.id,
            tasks_status: { ...taskStatus, completed: true },
          });
        }
      } else {
        if (!dbTaskStatusData) {
          this.UPDATE_GPT_SETUP_STATE(taskStatus);
          await this.DB_STORE_TASKS_STATUS({
            project_id: this.selectedProject.id,
            tasks_status: taskStatus,
          });
        } else {
          this.UPDATE_GPT_SETUP_STATE(dbTaskStatusData);
          await this.DB_STORE_TASKS_STATUS({
            project_id: this.selectedProject.id,
            tasks_status: dbTaskStatusData,
          });
        }
      }

      //check training status
      this.isSetupStateLoading = false;
      this.isLoading = false;
      if (!this.isGptInitialSetupCompleted) {
        this.tab = "gptSetupTab";
      }
    },

    async updateGptProfile(profiles) {
      this.UPDATE_GPT_SETUP_STATE({
        ...this.gptSetupState,
        gpt_profile: { completed: true, profile_id: profiles[0]?.id },
      });
    },

    async updateGptDataset(datasets) {
      this.GET_GPT_DATASET_BY_ID({
        project_id: this.selectedProject.id,
        dataset_id: datasets[0]?.dataset_id,
      }).then(
        res => {
          if (res.data.response?.dataset?.documents_count?.total > 0) {
            this.UPDATE_GPT_SETUP_STATE({
              ...this.gptSetupState,
              dataset: { completed: true, dataset_id: datasets[0]?.dataset_id },
            });
          }
        },
        err => {
          console.log("error", err);
        },
      );
    },
    async getAllTasks(task_type, task_status) {
      return this.GET_ALL_TASKS({
        project_id: this.$route.params.project_id,
        task_type,
        task_status,
      }).then(
        res => {
          // this.UPDATE_GPT_SETUP_STATE({key: "datasetCreated", value: true})
          return res.data;
        },
        err => {
          console.log("err", err.response);
        },
      );
    },
    getPreviouslySetupState() {
      this.isLoading = true;
      this.isSetupStateLoading = true;
      this.GET_PREVIOUSLY_SETUP_STATE({
        project_id: this.$route.params.project_id,
      }).then(
        async response => {
          const isInitialSetupDone =
            this.selectedProject?.setup_state &&
            this.selectedProject.setup_state.every(
              entry => entry.status === "success",
            );
          this.initialSetupDone = isInitialSetupDone;

          if (isInitialSetupDone !== true && this.selectedProject.id) {
            this.tab = "setup";
          } else if (this.selectedProject.id && !this.isSetupStateLoading) {
            this.tab = "settings";
          } else {
            const storageData = localStorage.getItem(
              SEGMENTS_TAB_STORAGE_KEYS.PERSONALIZER,
            );
            try {
              const jsonStorageData = JSON.parse(storageData);
              const savedTab = jsonStorageData[this.selectedProject.id].tab;
              if (
                PERSONALIZER_TABS.includes(savedTab) ||
                COMMON_TABS.includes(savedTab)
              ) {
                this.tab = savedTab;
              } else {
                this.tab = "details";
              }
            } catch (e) {
              this.tab = "details";
            }
          }
          this.isSetupStateLoading = false;
          this.isLoading = false;
          // Here we are updating global state : previouslySetupState
        },
        error => {
          this.isLoading = false;
          this.isSetupStateLoading = false;
        },
      );
    },
    getSolution(solution_id) {
      this.isLoading = true;
      this.LOAD_SOLUTION_BY_ID({
        solution_id: solution_id,
      }).then(
        response => {},
        error => {
          this.isLoading = false;
        },
      );
    },
    async postSetupFinishAction() {
      await this.getProject();
      this.tab = "settings";
    },
    getIframeUrl() {
      let accessToken = this.user.access_token;
      let baseUrl = this.gptFlowUrl;
      let queryParams = `?access_token=${accessToken}&project_id=${this.selectedProject.id}`;
      let iframeUrl = baseUrl + queryParams;
      return iframeUrl;
    },
  },
};
</script>

<style>
.tooltip {
  /* Container for our tooltip */
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  /* This is for the tooltip text */
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: white;
  text-align: center;
  padding: 1px 0;
  border-radius: 10px; /* This defines tooltip text position */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  /* Makes tooltip text visible when text is hovered on */
  visibility: visible;
}

@media (max-width: 767px) {
  iframe {
    width: 100%;
    height: 850px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1029px) {
  iframe {
    width: 100%;
    height: 850px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 180px;
  }
}
</style>
